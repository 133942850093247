<template>
  <div
    class="pt-3 pb-5 px-2 d-flex flex-column justify-content-center align-items-center"
  >
    <div class="d-flex bg-green mx-5 my-3 w-100 justify-content-center px-2">
      <span class="text-white">Información general</span>
    </div>
    <div
      class="px-2 my-3 mx-4 h-max-content brown-wGreyMedium rounded bg-white shadow-sm pointer d-flex flex-column justify-content-between text-grey-medium w-auto"
    >
      <span class="py-1 mx-3 align-self-center">Nombre: {{ box.name }}</span>

      <span class="py-1 mx-3 align-self-center"
        >Fecha instalación: {{ box.instalation_date }}</span
      >

      <span class="py-1 mx-3 align-self-center">Serial: {{ box.serial }}</span>

      <span class="py-1 mx-3 align-self-center"
        >Tipo: {{ box.description }}</span
      >
      <div class="align-self-center"></div>
    </div>

    <time-selector :range="{ end, start }" :box="box" />
    <div class="d-flex">
      <half-circle-card
        :DATA="
          box.values.find((v) => v.meassurement_info[0].name == 'humidity')
        "
      />
      <half-circle-card
        :DATA="
          box.values.find((v) => v.meassurement_info[0].name == 'temperature')
        "
      />
    </div>
    <div class="d-flex bg-green mx-5 my-3 justify-content-center w-100 px-2">
      <span class="text-white">Gráficas de condiciones ambientales</span>
    </div>
    <div class="d-flex">
      <span
        >Prediciones
        <input type="checkbox" v-model="predictionsActivated" />
      </span>
    </div>
    <graph-list :box="box" :predictionsActivated="predictionsActivated" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import TimeSelector from "./TimeSelector.vue"
import GraphList from "./GraphList.vue"

import HalfCircleCard from "./CustomGraphs/HalfCircleCard.vue"

export default {
  mounted() {
    const noFromSingle = !this.box.start || !this.box.end
    const notEqual = this.start !== this.box.start || this.end !== this.box.end

    console.log("NOW REQ oR ", noFromSingle, notEqual)
    if (noFromSingle || notEqual) {
      this.getRange({
        end: this.end,
        start: this.start,
        singleBox: this.box.serial
      })
    }
  },
  data() {
    return {
      predictionsActivated: true
    }
  },
  components: {
    TimeSelector,
    GraphList,

    HalfCircleCard
  },
  computed: {
    ...mapGetters(["boxBySerial", "start", "end"]),

    box() {
      if (this.$route.query.serial && this.$route.query.serial.length > 0) {
        return this.boxBySerial(this.$route.query.serial)
      } else {
        return false
      }
    },
    dateComputed() {
      return this.box
        ? new Date(this.box.updatedAt).toLocaleString().split(",")[0]
        : ""
    }
  },
  methods: {
    ...mapActions(["getRange"])
  }
}
</script>

<style></style>
