<template>
  <div class="container">
    <div
      v-if="
        $route.query &&
        !$route.query.mode &&
        !$route.query.single &&
        !$route.query.newBox &&
        !$route.query.tutorial &&
        !$route.query.serial
      "
    >
      <boxes-listing />
    </div>
    <div v-else-if="$route.query && $route.query.single">
      <single-box />
    </div>
    <div
      v-else-if="
        $route.query && $route.query.serial && $route.query.mode == 'edit'
      "
    >
      <box-info />
    </div>
    <div v-else-if="$route.query.newBox">
      <box-info />
    </div>
    <div v-else-if="$route.query.tutorial">
      <box-tutorial />
    </div>
  </div>
</template>

<script>
import SingleBox from "./SingleBox.vue"
import { mapActions } from "vuex"
import BoxTutorial from "./BoxTutorial.vue"
import BoxInfo from "./BoxInfo.vue"
export default {
  components: {
    "boxes-listing": () => import("@/components/Boxes/BoxesListing"),
    SingleBox,
    BoxTutorial,
    BoxInfo
  },
  mounted() {
    console.log()
    this.GET_OBJECTS("measurements").then(() => {
      //console.log("AFTER FETCH", this.$store.state);
      this.loading = false
    })
  },
  methods: {
    ...mapActions(["GET_OBJECTS"])
  }
}
</script>

<style></style>
