<template>
  <div>
    <!-- Selector -->
    <VueCtkDateTimePicker
      v-model="comNV"
      :range="true"
      label="Selecciona fechas"
      :format="'YYYY-MM-DD'"
      :overlay="true"
      locale="es"
      button-now-translation="Ahora"
      :max-date="nowDate"
      @is-hidden="onChange"
    />
    <span> {{ computeDate(range.start) }} / {{ computeDate(range.end) }}</span>
  </div>
</template>

<script>
//import "@/styles/vue-ctk-date-time-picker.scss"
import { mapActions } from "vuex"
export default {
  props: ["range", "box"],
  data() {
    return {
      yourValue: {},
      nowDate: new Date().toLocaleString(),
      nV: null
    }
  },
  components: {
    "vue-ctk-date-time-picker": () => import("vue-ctk-date-time-picker")
  },
  computed: {
    comNV: {
      set: function (nValue) {
        //console.log("NEW VALUE", nValue);
        this.nV = nValue
        //this.getRange(nValue)
      },
      get: function () {
        return this.nV ? this.nV : this.range
      }
    },
    computedRange: {
      set: function (nValue) {
        // console.log("NEW VALUE", nValue);
        this.yourValue = nValue
      },
      get: function () {
        const rta = {}
        rta["start"] =
          this.yourValue && this.yourValue.start
            ? this.yourValue.start
            : this.range.start
        rta["end"] =
          this.yourValue && this.yourValue.end
            ? this.yourValue.end
            : this.range.end
        return rta
      }
    }
  },
  methods: {
    ...mapActions(["getRange"]),
    computeDate(date) {
      return date ? date.split("T")[0] : ""
    },
    onChange() {
      //console.log("eeeeeee", e);
      if (this.nV && this.nV.start && this.nV.end) {
        this.getRange({ ...this.nV, singleBox: this.box.serial })
      }
    }
  }
}
</script>

<style></style>
