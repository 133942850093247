<script>
import { Doughnut } from "vue-chartjs"
//import ChartDataLabels from "chartjs-plugin-datalabels"
export default {
  extends: Doughnut,
  props: ["valueDou", "settings"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: ["", "l", ""],
          datasets: [
            {
              //data: [49, 1, 49],
              data: this.valueDou,
              /*  backgroundColor: [
                "rgba(0,0,0,0)",
                "rgba(255,255,255,1)",
                "rgba(0,0,0,0)"
              ], */
              backgroundColor: ["rgba(0,0,0,0)", "#2D3D24", "rgba(0,0,0,0)"],
              borderColor: ["rgba(0, 0, 0 ,0)", "#2D3D24", "rgba(0, 0, 0 ,0)"],
              borderWidth: 1
            }
          ]
        },

        {
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          },
          responsive: false,
          cutoutPercentage: 10,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          },
          scales: { display: false }
        }
      )
    }
  },
  mounted() {
    // Overwriting base render method with actual data.

    this.render()
  },
  computed: {
    data() {
      return this.valueDou
    }
  },
  watch: {
    data() {
      this.render()
    }
  }
}
</script>
