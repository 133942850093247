<template>
  <div
    class=""
    :style="`height: ${height}px; max-height: ${height}px; width: ${width}px ; max-width: ${width}px`"
  >
    <!-- <div
    class="container"
    v-bind:style="{ height: `${height}px`, width: `${width}px !important` }"
  > -->

    <doughnut-scale
      :styles="{
        position: 'absolute',
        height: `${height}px`,
        'z-index': 10,
        width: `${width}px`
      }"
      :width="width"
      :height="height"
      :scaleRanges="scaleRanges"
      :scaleLabels="scaleLabels"
      :settings="settings"
    />
    <doughnut-inner
      :styles="{
        position: 'absolute',
        height: `${height}px`,
        'z-index': 20,
        width: `${width}px`
      }"
      :width="width"
      :height="height"
      :ranges="ranges"
      :settings="settings"
    />
    <doughnut-outer
      :styles="{
        position: 'absolute',
        height: `${height}px`,
        width: `${width}px`
      }"
      :width="width"
      :height="height"
      :ranges="ranges"
      :settings="settings"
    />
    <doughnut-value
      :styles="{
        position: 'absolute',
        height: `${height}px`,
        width: `${width}px`,
        'z-index': 23
      }"
      :width="width"
      :height="height"
      :valueDou="valueDou"
      :settings="settings"
    />

    <!-- <p class="percent">89%</p> -->
  </div>
</template>
<script>
import DoughnutValue from "./DoughnutValue.vue"
import DoughnutOuter from "./DoughnutOuter.vue"
import DoughnutInner from "./DoughnutInner.vue"
import Radar from "./Radar.vue"
import DoughnutScale from "./DoughnutScale.vue"
export default {
  props: ["height", "width", "value", "valueComputed"],
  components: {
    DoughnutOuter,
    DoughnutInner,
    DoughnutValue,
    Radar,
    DoughnutScale
  },
  data() {
    const tempSettings = {
      maxRange: 50,
      minRange: 0,
      rangeColor: "#EFC59F"
    }
    const humiditySetting = {
      maxRange: 100,
      minRange: 0,
      rangeColor: "rgb(199, 226, 232,0.5)"
    }

    return {
      settings:
        this.value.meassurement_info[0].name == "temperature"
          ? tempSettings
          : humiditySetting
    }
  },
  computed: {
    rangeRatio() {
      return 100 / this.settings.maxRange
    },
    scaleLabels() {
      const { max, min } = this.value
      let data = []
      data.push(0)
      data.push(null)
      data.push(Number(min).toFixed(1))
      data.push(null)
      data.push(Number(max).toFixed(1))
      data.push(null)
      data.push(this.settings.maxRange)
      return data
    },
    scaleRanges() {
      const { max, min } = this.value
      let data = []
      let dif = max - min
      data.push(1)
      data.push(min * this.rangeRatio)
      data.push(1)
      data.push(dif * this.rangeRatio)
      data.push(1)
      data.push(100 - (dif + min) * this.rangeRatio)
      data.push(1)
      return data
    },
    ranges() {
      const { max, min } = this.value
      let data = []
      let dif = max - min
      data.push(min * this.rangeRatio)
      data.push(dif * this.rangeRatio)
      data.push(100 - (dif + min) * this.rangeRatio)
      return data
    },
    valueDou() {
      let data = []

      data.push(this.valueComputed * this.rangeRatio)
      data.push(2)
      data.push(100 - (this.valueComputed * this.rangeRatio + 2))
      return data
    }
  }
}
</script>

<style>
.percent {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 80px;
  bottom: 0;
}
</style>
