<template>
  <div>
    <div
      v-for="(key, index) in [
        'T',
        'H',
        'L0',
        'L1',
        'P',
        'CO2',
        'irrS',
        'ligS',
        'R',
        'battery',
        'signal',
        'samples'
      ]"
      :class="key"
      :key="index"
    >
      <chart
        v-if="getValue(key)"
        :predictionsActivated="predictionsActivated"
        :obj="getValue(key)"
        :debug="key == 'T'"
        :style="{
          display: getValue(key) ? 'block' : 'none',
          width: computedWidth
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Chart from "./Chart.vue"
export default {
  components: {
    Chart
  },
  props: ["box", "predictionsActivated"],
  computed: {
    ...mapGetters(["screen_size"]),
    computedWidth() {
      if (this.screen_size) {
        return this.screen_size.width + "px"
      } else return "100px"
    },
    mTypes() {
      return !this.box
        ? []
        : this.box.values.map((v) => {
            if (v.meassurement_info[0]._id != "A") {
              return v
            } else {
              return null
            }
          })
    }
  },
  methods: {
    getValue(key) {
      return this.box.values.find((v) => v._id == key)
    }
  }
}
</script>

<style></style>
