<script>
import { Pie } from "vue-chartjs"
import ChartDataLabels from "chartjs-plugin-datalabels"
export default {
  extends: Pie,
  props: ["scaleRanges", "scaleLabels", "settings"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    render() {
      this.addPlugin(ChartDataLabels)
      this.renderChart(
        {
          labels: ["Green", "Red", "Blue"],
          datasets: [
            {
              backgroundColor: "rgba(0,0,0,0)",

              borderColor: "rgba(0,0,0,0)",

              data: this.scaleRanges,
              datalabels: {
                //anchor: "end"
                //align: "end"
              }
            }
          ]
        },
        {
          plugins: {
            datalabels: {
              anchor: "end",
              align: "end",
              offset: 0,
              color: "black",
              textAlign: "center",

              font: {
                weight: "500",
                size: 8
              },
              formatter: function (value, ctx) {
                if (value == 1) {
                  return this.scaleLabels[ctx.dataIndex]
                } else {
                  return ""
                }
              }.bind(this)
            }
          },
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          },
          responsive: false,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          }
          /*   aspectRatio: 4 / 3,
          cutoutPercentage: 32,

          elements: {
            line: {
              fill: false
            },
            point: {
              hoverRadius: 7,
              radius: 5
            }
          } */
        }
      )
    }
  },
  mounted() {
    // Overwriting base render method with actual data.

    this.render()
  },
  computed: {
    data() {
      return this.scaleRanges
    }
  },
  watch: {
    data() {
      this.render()
    }
  }
}
</script>
<style scoped></style>
