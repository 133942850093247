<template>
  <div class="d-flex flex-column">
    <div class="align-self-start d-flex">
      <inline-svg
        @click="$router.push(-1)"
        class="align-self-start t-rotate-180"
        src="/flecha-ladoder.svg"
        width="45px"
        height="45px"
        fill="white"
        aria-label="Loading..."
      />
    </div>
    <div
      class="
        align-self-center
        col-10 col-md-6 col-xl-4
        bg-white
        rounded
        my-3
        p-4 p-md-5
        shadow
        d-flex
        flex-column
        align-items-center
      "
    >
      <label
        :class="{
          'mb-3 w-100': true,
          error: $v.name.$error,
          green: $v.name.$dirty && !$v.name.$error
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="name"
          @change="$v.name.$touch()"
          placeholder="Lugar / Ubicación"
        />
      </label>
      <transition-expanded>
        <div class="align-self-start" v-if="$v.name.$dirty && $v.name.$error">
          <p class="font-rem-0-75 error">
            Debe ser un nombre de almenos 4 caracteres
          </p>
        </div>
      </transition-expanded>

      <label
        :class="{
          'mb-3 w-100': true
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="instalation_date"
          type="date"
          placeholder="Fecha de instalación"
        />
      </label>

      <label
        :class="{
          'mb-3 w-100': true,
          error: $v.serial.$error,
          green: $v.serial.$dirty && !$v.serial.$error
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="serial"
          @change="$v.serial.$touch()"
          :disabled="$route.query.newBox ? false : true"
          placeholder="ID MidBox"
        /> </label
      ><transition-expanded>
        <div
          class="align-self-start"
          v-if="$v.serial.$dirty && $v.serial.$error"
        >
          <p class="font-rem-0-75 error">
            Debe ser un serial valido, de tipo `w_#`
          </p>
        </div>
      </transition-expanded>

      <label
        :class="{
          'mb-3 w-100': true,
          error: $v.description.$error,
          green: $v.description.$dirty && !$v.description.$error
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="description"
          @change="$v.description.$touch()"
          @input="$v.$touch()"
          placeholder="Tipo de Cultivo"
        />
      </label>
      <transition-expanded>
        <div
          class="align-self-start"
          v-if="$v.description.$dirty && $v.description.$error"
        >
          <p class="font-rem-0-75 error">debe tener almenos 4 caracteres</p>
        </div>
      </transition-expanded>
      <label
        v-if="isMType"
        :class="{
          'mb-3 w-100': true
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="temperatureDesired"
          @input="$v.$touch()"
          placeholder="desired temperature"
        />
      </label>
      <label
        v-if="isMType || isAType"
        :class="{
          'mb-3 w-100': true
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="lightOn"
          type="time"
          @input="$v.$touch()"
          placeholder="Light On"
        />
      </label>
      <label
        v-if="isMType || isAType"
        :class="{
          'mb-3 w-100': true
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <i class="glyphicon glyphicon-cloud"></i>
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="lightOff"
          type="time"
          @input="$v.$touch()"
          placeholder="Lights Off"
        />
      </label>
      <label
        v-if="isAType"
        :class="{
          'mb-3 w-100': true,
          error: $v.t_on.$error,
          green: $v.t_on.$dirty && !$v.t_on.$error
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="t_on"
          @change="$v.t_on.$touch()"
          @input="$v.$touch()"
          type="number"
          placeholder="Tiempo de encendido"
        />
      </label>
      <transition-expanded>
        <div class="align-self-start" v-if="$v.t_on.$dirty && $v.t_on.$error">
          <p class="font-rem-0-75 error">invalid t_on</p>
        </div>
      </transition-expanded>
      <label
        v-if="isAType"
        :class="{
          'mb-3 w-100': true,
          error: $v.t_off.$error,
          green: $v.t_off.$dirty && !$v.t_off.$error
        }"
      >
        <!-- <span class="d-block mb-2">Correo electrónico</span> -->
        <input
          class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
          v-model="t_off"
          @change="$v.t_off.$touch()"
          @input="$v.$touch()"
          type="number"
          placeholder="Tiempo de apagado"
        />
      </label>
      <transition-expanded>
        <div class="align-self-start" v-if="$v.t_off.$dirty && $v.t_off.$error">
          <p class="font-rem-0-75 error">invalid t_off</p>
        </div>
      </transition-expanded>
      <label
        :class="{
          'mb-3 w-100': true
        }"
      >
        <span class="d-block mb-2">Localización caja:</span>
        <button @click="showMap = !showMap">
          <inline-svg
            class="flex-grow-1"
            src="/localizacion.svg"
            fill="black"
            width="30px"
            height="30px"
            aria-label="Loading..."
          />
        </button>
      </label>

      <button
        class="
          py-2
          px-3
          mt-3
          w-75
          h-50
          rounded
          bg-dark-green
          shadow-sm
          pointer
          d-flex
          justify-content-center
          text-white
          w-fit-content
        "
        :disabled="$v.$invalid"
        @click="saveClick()"
      >
        <span v-if="$route.query.newBox" class="align-self-center">
          registrar caja</span
        >
        <span v-else class="align-self-center"> Guardar cambios</span>
      </button>
      <button
        class="
          py-2
          px-3
          mt-3
          w-75
          h-50
          rounded
          bg-red
          shadow-sm
          pointer
          d-flex
          justify-content-center
          text-white
          w-fit-content
        "
        v-if="!$route.query.newBox"
        @click="deleteClick()"
      >
        <span class="align-self-center"> Eliminar Caja</span>
      </button>
    </div>
    <transition name="slide">
      <w-map
        v-if="showMap"
        :style="{
          position: 'fixed',
          right: '0',
          bottom: '0',
          width: '100%',
          height: '100%'
        }"
        :box="box"
        @closeMap="showMap = false"
        @setLocation="setLocation"
      />
      <!-- <div
        v-if="showMap"
        :style="{
          position: 'fixed',
          right: '0',
          bottom: '0',
          width: '100%',
          height: '100%'
        }"
        class="bg-grey"
      >
        c
      </div> -->
    </transition>
  </div>
</template>

<script>
import {
  required,
  minLength,
  helpers,
  minValue,
  numeric,
  integer
} from "vuelidate/lib/validators"
import { mapGetters, mapActions } from "vuex"
import utils from "@/mixins/utils"

const serial = helpers.regex("serial", /^[mwa]_[1234567890]+$/)

export default {
  components: {
    "transition-expanded": () => import("@/components/TransitionExpanded"),
    "w-map": () => import("@/components/Boxes/WMap")
  },
  mixins: [utils],
  data() {
    return {
      showMap: false,
      box: {
        name: undefined,
        instalation_date: undefined,
        serial: undefined,
        description: undefined,
        geoReference: undefined,
        temperatureDesired: undefined,
        lightOn: undefined,
        lightOff: undefined,
        t_on: undefined,
        t_off: undefined
      }
    }
  },
  created() {
    this.initBox()
  },
  mounted() {
    this.initBox()
  },
  computed: {
    ...mapGetters(["boxBySerial"]),
    isMType() {
      return this.serial ? this.serial.includes("m") : false
    },
    isAType() {
      return this.serial ? this.serial.includes("a_") : false
    },
    temperatureDesired: {
      get() {
        return this.box.temperatureDesired
      },
      set(value) {
        this.box.temperatureDesired = value
      }
    },
    t_on: {
      get() {
        return this.box.t_on
      },
      set(value) {
        this.box.t_on = value
      }
    },
    t_off: {
      get() {
        return this.box.t_off
      },
      set(value) {
        this.box.t_off = value
      }
    },
    lightOn: {
      get() {
        return this.box.lightOn
      },
      set(value) {
        this.box.lightOn = value
      }
    },
    lightOff: {
      get() {
        return this.box.lightOff
      },
      set(value) {
        this.box.lightOff = value
      }
    },
    name: {
      get() {
        return this.box.name
      },
      set(value) {
        this.box.name = value
      }
    },
    instalation_date: {
      get() {
        return this.box.instalation_date
      },
      set(value) {
        this.box.instalation_date = value
      }
    },
    serial: {
      get() {
        return this.box.serial
      },
      set(value) {
        this.box.serial = value
      }
    },
    description: {
      get() {
        return this.box.description
      },
      set(value) {
        this.box.description = value
      }
    }
  },
  methods: {
    ...mapActions(["REGISTER_BOX", "UPDATE_BOX", "DELETE_BOX"]),
    locateBox() {
      console.log("LOCATE MAP OPEN")
      this.showMap = true
    },
    setLocation(location) {
      this.box.geoReference = location
      this.showMap = false
    },
    initBox() {
      if (this.$route.query.serial) {
        const fullB = this.boxBySerial(this.$route.query.serial)
        this.box._id = fullB._id
        this.box.name = fullB.name
        this.box.instalation_date = fullB.instalation_date
        this.box.serial = fullB.serial
        this.box.description = fullB.description
        this.box.geoReference = fullB.geoReference
        this.box.temperatureDesired = fullB.temperatureDesired
        this.box.lightOn = fullB.lightOn
        this.box.lightOff = fullB.lightOff
        this.box.t_on = fullB.t_on
        this.box.t_off = fullB.t_off
      }
    },
    async deleteClick() {
      const rta = await this.DELETE_BOX(this.box)
      if (rta) {
        this.$router.push({
          name: "View",
          params: { view: "boxes" },
          query: {}
        })
      } else {
        // no paso nada, alert
        this.boxAlert()
      }
    },
    saveClick() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.$route.query.newBox) {
          this.registerBox()
        } else {
          this.navUpdate()
        }
      }
    },
    async registerBox() {
      console.log("ready cto signUp", this.box)
      const rta = await this.REGISTER_BOX(this.box)
      if (rta) {
        this.$router.push({
          name: "View",
          params: { view: "boxes" },
          query: { newBox: undefined, tutorial: true, serial: this.box.serial }
        })
      } else {
        // no paso nada, alert
        this.boxAlert()
      }
    },
    async navUpdate() {
      console.log(this.box)
      /* console.log(this.box.lightOn > this.box.lightOff)
      console.log(this.box.lightOn == this.box.lightOff)
      console.log(this.box.lightOn < this.box.lightOff) */
      const rta = await this.UPDATE_BOX(this.box)
      if (rta) {
        this.$router.push({
          name: "View",
          params: { view: "boxes" },
          query: { newBox: undefined, tutorial: undefined, serial: undefined }
        })
      } else {
        // no paso nada, alert

        this.boxAlert()
      }
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },

    serial: {
      required,
      serial
    },
    description: {
      required,
      minLength: minLength(4)
    },
    t_on: {
      numeric,
      integer,
      minValue: minValue(1)
    },
    t_off: {
      numeric,
      integer,
      minValue: minValue(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: margin-bottom 0.5s ease-out;
}

.slide-enter,
.slide-leave-to {
  margin-bottom: -200px;
}

.slide-enter-to,
.slide-leave {
  margin-bottom: 0px;
}

.error {
  color: $wAlert !important;
  label {
    color: $wAlert;
  }
  input,
  textarea {
    border: 1px $wAlert solid !important;
  }
  .field-input {
    border: 1px $wAlert solid !important;
  }
  .multiselect__tags {
    border: 1px $wAlert solid !important;
  }
}
.green {
  input {
    border: 1px $wGreenSucc solid !important;
  }
  .field-input {
    border: 1px $wGreenSucc solid !important;
  }
  .multiselect__tags {
    border: 1px $wGreenSucc solid !important;
  }
}
</style>
