<script>
import Chart from "chart.js"
import { Line } from "vue-chartjs"
import utils from "@/mixins/utils"
export default {
  mixins: [utils],
  extends: Line,
  props: ["obj", "debug", "predictionsActivated"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null
    },
    render() {
      this.CANVAS = this.$refs.canvas
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450)

      const colorRgb = this.hexToRgb(this.colorLine)

      this.gradient.addColorStop(
        0,
        `rgba(${colorRgb.r}, ${colorRgb.g},${colorRgb.b}, 0.5)`
      )
      this.gradient.addColorStop(
        0.5,
        `rgba(${colorRgb.r}, ${colorRgb.g},${colorRgb.b}, 0.25)`
      )
      this.gradient.addColorStop(
        1,
        `rgba(${colorRgb.r}, ${colorRgb.g},${colorRgb.b}, 0)`
      )
      const conf = this.configData

      if (this.debug) console.log("mergedData", conf)
      //console.log("hello render config", conf)
      this.renderChart(conf, {
        layout: {
          padding: {
            left: 20,
            right: 10,
            top: 10,
            bottom: 30
          }
        },
        legend: {
          display: true,
          position: "bottom",
          font: { family: "'brown'" }
        },
        title: {
          display: true,
          text: this.title,
          font: { family: "'brown-italic'" }
        },
        responsive: true,
        maintainAspectRatio: true,
        /* tooltips: {
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              //console.log("hello tool tips", tooltipItem, data)
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const index = tooltipItem.index
              return dataset.labels[index] + ": $" + dataset.data[index]
            }
          }
        }, */
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                parser: "MM/DD/YYYY HH:mm",
                // round: 'day'
                tooltipFormat: "ll HH:mm"
              },
              gridLines: {
                color: "#B7B7B7",
                zeroLineColor: "rgba(0, 0, 0, 0.1)"
              },
              ticks: {
                display: true,
                beginAtZero: true
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "#B7B7B7",
                zeroLineColor: "rgba(0, 0, 0, 0.1)"
              },
              ticks: {
                display: true,
                beginAtZero: false,
                callback: (value /* index, ticks */) => {
                  return value + " " + this.meassurementInfo.symbol
                }
              }
              /* scaleLabel: {
                  display: true,
                  labelString: "probability"
                } */
            }
          ]
        }
      })
    },
    getDataSets(input) {
      const realData = input.realData
      const predictionData = input.predictionData
      if (this.obj.prediction) {
        return [
          {
            label: this.meassurementInfo.legend,
            backgroundColor: this.gradient,
            data: realData,
            //labels: this.wLabels,
            borderColor: this.colorLine,
            pointBackgroundColor: "rgb(1,1,1,0)",
            borderWidth: 2,
            pointBorderColor: "rgb(1,1,1,0)",
            pointBorderWidth: 0.1,
            pointRadius: 10,
            fill: true
          },
          {
            label: "predición " + this.meassurementInfo.legend,
            //backgroundColor: this.gradient,
            data: predictionData,
            //labels: newLabels.reverse(),
            borderColor: "black", //this.colorLine,
            pointBackgroundColor: "rgb(1,1,1,0)",
            borderWidth: 2,
            pointBorderColor: "rgb(1,1,1,0)",
            pointBorderWidth: 0.1,
            pointRadius: 10,
            fill: false
          }
        ]
      }
    }
  },
  mounted() {
    // Overwriting base render method with actual data.
    if (this.obj) {
      Chart.defaults.global.defaultFontFamily = "brown"
      this.render()
      //console.log("ESTO RECIBI DE DATA", this.$refs)
    }
  },
  computed: {
    configData() {
      const dataP = this.mergedData
      console.log(
        "mergedData",
        this.predictionsActivated,
        (this.obj.prediction ?? false) && this.predictionsActivated
      )
      if ((this.obj.prediction ?? false) && this.predictionsActivated) {
        return {
          labels: dataP.labels,
          datasets: this.getDataSets(dataP)
        }
      } else {
        return {
          labels: this.wLabels,
          datasets: this.wDataSets
        }
      }
    },
    // method in charge of converting/mergin predictions and current data
    mergedData() {
      if ((this.obj.prediction ?? false) && this.predictionsActivated) {
        // total lenght of x and y
        const total_length =
          this.labels.length + this.obj.prediction.labels.length
        // common labels, x axe
        const normal_labels = []

        const real_values = []
        const prediction_values = []
        let i = 0
        let real_index = 0
        let prediction_index = 0
        let not_finished_yet = true

        // suposed total predictions
        const total_predictions = this.obj.prediction.labels.length
        let previous_predictions = 0
        // we will iterate until we have a real_values , and prediction_values of equal size
        while (i < total_length && not_finished_yet) {
          //
          const select_label =
            this.labels[this.labels.length - (real_index + 1)]
          const label_date = new Date(select_label)
          const value = this.data[this.data.length - (real_index + 1)]

          let select_prediction_label =
            this.obj.prediction.labels[prediction_index]
          select_prediction_label = this.getFormatedOffsetDate(
            select_prediction_label
          )
          // fix to 5 hours bus on predictions, tech debt
          // this should be removed
          const date_before_fix = new Date(select_prediction_label)
          const hours_fix = 5
          const final_prediction_date = date_before_fix.setHours(
            date_before_fix.getHours() - hours_fix
          )
          const final_prediction_value =
            this.obj.prediction.values[prediction_index]
          const time_diff = label_date - final_prediction_date
          if (this.debug) {
            console.log(
              i,
              time_diff,
              select_label,
              label_date,
              select_prediction_label,
              date_before_fix
            )
          }

          // is not any more value, maybe no more real of predictions values
          if (isNaN(time_diff)) {
            // in case there is no prediction value,
            if (!select_prediction_label) {
              normal_labels.push(label_date)
              real_values.push({ x: label_date, y: value })
              //predValues.push({ x: label, y: null })
              real_index++
            }
            if (!select_label) {
              normal_labels.push(final_prediction_date)
              //realValues.push({ x: fDate, y: null })
              prediction_values.push({
                x: final_prediction_date,
                y: final_prediction_value
              })
              prediction_index++
            }
          }
          // negative, means prediction date is future, after actual
          else if (time_diff < 0) {
            normal_labels.push(label_date)
            real_values.push({ x: label_date, y: value })
            //predValues.push({ x: label, y: null })
            real_index++
            //label_date is en future, after first_predict
          } else if (time_diff > 0) {
            if (real_index > 0) {
              // we are not goint to paint them, so coment this,
              normal_labels.push(final_prediction_date)
              //realValues.push({ x: fDate, y: null })
              prediction_values.push({
                x: final_prediction_date,
                y: final_prediction_value
              })
              //prediction_index++
            }
            if (real_index == 0) {
              previous_predictions++
            }
            prediction_index++
          }
          // variables step
          if (
            real_index == this.data.length &&
            prediction_index == total_predictions - previous_predictions
          ) {
            not_finished_yet = false
          }
          /* console.log(
            "out bound",
            rIndex,
            predIndex,
            nLabels.length,
            this.data.length,
            this.obj.prediction.labels.length
          ) */

          i++
        }
        return {
          labels: normal_labels,
          realData: real_values,
          predictionData: prediction_values
        }
      } else {
        return null
      }
    },
    wLabels() {
      //console.log("Hello world", this.obj.prediction)
      return this.labels.map((e) => {
        //const string = e.split(".")[0]
        //const nDate = new Date(string)

        //console.log(typeof e, new Date(e), e)
        return new Date(e)
      })
    },
    wDataSets() {
      //console.log("Hello world", this.obj.prediction)

      return [
        {
          label: this.meassurementInfo.legend,
          backgroundColor: this.gradient,
          data: this.data,
          borderColor: this.colorLine,
          pointBackgroundColor: "rgb(1,1,1,0)",
          borderWidth: 2,
          pointBorderColor: "rgb(1,1,1,0)",
          pointBorderWidth: 0.1,
          pointRadius: 10,
          fill: true
        }
      ]
    },
    title() {
      const portS = this.obj._id.includes("L")
        ? " " + this.obj._id.replace("L", "")
        : ""
      return this.meassurementInfo.label + portS
    },
    data() {
      return this.obj.values.map((v) => Number(v).toFixed(2))
    },
    labels() {
      return this.obj.labels
    },
    meassurementInfo() {
      return this.obj.meassurement_info[0]
    },
    yLabel() {
      return this.meassurementInfo.label
    },
    xLabel() {
      return this.meassurementInfo.units
    },
    colorLine() {
      if (this.meassurementInfo.name == "light") {
        return "#F1D95E"
      } else if (this.meassurementInfo.name == "humidity") {
        return "#A8D5DF"
      } else if (this.meassurementInfo.name == "temperature") {
        return "#EFC59F"
      } else {
        return "#000000"
      }
    }
  },
  watch: {
    predictionsActivated() {
      console.log("chanfed", this.predictionsActivated)
      this.render()
    },
    data() {
      this.render()
    }
  }
}
</script>
