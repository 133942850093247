var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("height: " + _vm.height + "px; max-height: " + _vm.height + "px; width: " + _vm.width + "px ; max-width: " + _vm.width + "px"))},[_c('doughnut-scale',{attrs:{"styles":{
      position: 'absolute',
      height: (_vm.height + "px"),
      'z-index': 10,
      width: (_vm.width + "px")
    },"width":_vm.width,"height":_vm.height,"scaleRanges":_vm.scaleRanges,"scaleLabels":_vm.scaleLabels,"settings":_vm.settings}}),_c('doughnut-inner',{attrs:{"styles":{
      position: 'absolute',
      height: (_vm.height + "px"),
      'z-index': 20,
      width: (_vm.width + "px")
    },"width":_vm.width,"height":_vm.height,"ranges":_vm.ranges,"settings":_vm.settings}}),_c('doughnut-outer',{attrs:{"styles":{
      position: 'absolute',
      height: (_vm.height + "px"),
      width: (_vm.width + "px")
    },"width":_vm.width,"height":_vm.height,"ranges":_vm.ranges,"settings":_vm.settings}}),_c('doughnut-value',{attrs:{"styles":{
      position: 'absolute',
      height: (_vm.height + "px"),
      width: (_vm.width + "px"),
      'z-index': 23
    },"width":_vm.width,"height":_vm.height,"valueDou":_vm.valueDou,"settings":_vm.settings}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }