<script>
import { Radar } from "vue-chartjs"
export default {
  extends: Radar,
  props: ["ranges"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: [...Array(180).keys()],
          datasets: [
            {
              label: "# of Votes",
              //data: [1, 2, 3, 4, 5],
              backgroundColor: [
                "rgb(199, 226, 232,0.5)",
                "rgba(0,0,0,0)",
                "rgb(199, 226, 232,0.5)"
              ],
              borderColor: "black",
              /* borderColor: [
                "rgba(255, 255, 255 ,1)",
                "rgba(255, 255, 255 ,1)",
                "rgba(255, 255, 255 ,1)"
              ], */
              borderWidth: 4,
              fill: true
            }
          ]
        },
        {
          /* rotation: 1 * Math.PI,
          circumference: 1 * Math.PI, */
          scale: {
            ticks: {
              beginAtZero: true
            }
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          cutoutPercentage: 65
          /* scale: {
            angleLines: { display: true },
            ticks: {
              beginAtZero: true,
              suggestedMin: 50,
              suggestedMax: 100,
              stepSize: 1
            }
          } */
        }
      )
    }
  },
  mounted() {
    // Overwriting base render method with actual data.

    this.render()
  },

  watch: {
    data() {
      this.render()
    }
  }
}
</script>
