<template>
  <div
    class="
      d-flex
      flex-column
      py-2
      justify-content-center
      align-items-center
      position-relative
      bg-white
      rounded
      m-1
    "
  >
    <h3 class="font-rem-0-8 brown-green-bold">
      {{ DATA.meassurement_info[0].label }}
    </h3>
    <span class="py-2 font-rem-0-8 brown-green-regular"
      >{{ Number(valueComputed).toFixed(1) }}
      {{
        DATA.meassurement_info[0].symbol
          ? DATA.meassurement_info[0].symbol
          : "n/a"
      }}</span
    >
    <circle-graph
      :valueComputed="valueComputed"
      :value="DATA"
      :width="width"
      :height="height"
    />
    <div class="d-flex flex-column align-self-start px-2">
      <h4 class="font-rem-0-7 brown-green-bold justify-content-start text-left">
        Mínimo: {{ Number(DATA.min).toFixed(1)
        }}{{
          DATA.meassurement_info[0].symbol
            ? DATA.meassurement_info[0].symbol
            : "n/a"
        }}
      </h4>

      <h4
        class="font-rem-0-7 brown-green-bold justify-content-star text-left t"
      >
        Máximo: {{ Number(DATA.max).toFixed(1)
        }}{{
          DATA.meassurement_info[0].symbol
            ? DATA.meassurement_info[0].symbol
            : "n/a"
        }}
      </h4>

      <h4 class="font-rem-0-7 brown-green-bold justify-content-start text-left">
        Promedio: {{ Number(valueComputed).toFixed(1)
        }}{{
          DATA.meassurement_info[0].symbol
            ? DATA.meassurement_info[0].symbol
            : "n/a"
        }}
      </h4>
    </div>

    <!-- <circle-graph
      :value="box.values.find((v) => v.meassurement_info[0].name == 'humidity')"
      :width="width"
      :height="height"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CircleGraph from "./CircleGraph.vue"
export default {
  props: ["DATA"],
  components: {
    CircleGraph
  },
  computed: {
    ...mapGetters(["boxBySerial", "start", "end"]),
    valueComputed() {
      const day = 86400000

      const dStar = Date.parse(this.start)
      const dEnd = Date.parse(this.end)
      if (dEnd - dStar == day && new Date() - dEnd < 120000) {
        return this.DATA.values[this.DATA.values.length - 1]
      } else return this.DATA.average
    }
  },

  data() {
    return { width: 140, height: 100 }
  }
}
</script>

<style></style>
