<script>
import { Doughnut } from "vue-chartjs"
export default {
  extends: Doughnut,
  props: ["ranges", "settings"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: ["black", "red", "black"],
          datasets: [
            {
              label: "# of Votes",
              data: this.ranges,
              backgroundColor: ["rgba(0,0,0,0)", "#5CAABC", "rgba(0,0,0,0)"],
              borderColor: ["rgba(0,0,0,0)", "#5CAABC", "rgba(0,0,0,0)"],
              borderWidth: [0, 3, 0],
              fill: true
            }
          ]
        },
        {
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          },
          responsive: false,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          cutoutPercentage: 1,
          scales: {
            r: {
              angleLines: { display: true },
              ticks: {
                beginAtZero: true,
                suggestedMin: 50,
                suggestedMax: 100,
                stepSize: 1
              }
            }
          }
        }
      )
    }
  },
  mounted() {
    // Overwriting base render method with actual data.

    this.render()
    //"ESTO RECIBI DE DATA", this.$refs)
  },
  computed: {
    data() {
      return this.ranges
    }
  },
  watch: {
    data() {
      this.render()
    }
  }
}
</script>
<style scoped>
.color {
  background-color: rgb(red, green, blue);
}
</style>
