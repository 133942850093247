var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(
      _vm.$route.query &&
      !_vm.$route.query.mode &&
      !_vm.$route.query.single &&
      !_vm.$route.query.newBox &&
      !_vm.$route.query.tutorial &&
      !_vm.$route.query.serial
    )?_c('div',[_c('boxes-listing')],1):(_vm.$route.query && _vm.$route.query.single)?_c('div',[_c('single-box')],1):(
      _vm.$route.query && _vm.$route.query.serial && _vm.$route.query.mode == 'edit'
    )?_c('div',[_c('box-info')],1):(_vm.$route.query.newBox)?_c('div',[_c('box-info')],1):(_vm.$route.query.tutorial)?_c('div',[_c('box-tutorial')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }