<template>
  <div class="w-auto h-auto d-flex flex-column mx-2 my-2 py-2">
    <div class="d-flex justify-content-between">
      <inline-svg
        class="align-self-center t-rotate-180"
        src="/flecha-ladoder.svg"
        width="45px"
        height="45px"
        fill="white"
        aria-label="Loading..."
        @click="prevStep()"
      />
      <div class="d-flex justify-self-center" v-show="internalStep < 4">
        <div
          v-show="internalStep < 4"
          v-for="index in [0, 1, 2, 3]"
          :key="index"
          :class="`align-self-center rounded-circle align-self-center  mx-1 my-1  ${
            internalStep >= index ? 'bg-green' : 'bg-gray-dark'
          }`"
          :style="{ width: '12px', height: '12px' }"
        >
          <div
            class="rounded-circle invisible"
            :style="{ width: '12px', height: '12px' }"
          >
            c
          </div>
        </div>
      </div>
      <inline-svg
        :class="{
          'align-self-center justify-self-end': true,
          invisible: !(internalStep < 3)
        }"
        src="/flecha-ladoder.svg"
        width="45px"
        height="45px"
        fill="white"
        aria-label="Loading..."
        @click="nextStep()"
      />
    </div>

    <div
      id="touch-element"
      ref="touch"
      class="vh-75"
      :style="{ width: '300px' }"
    >
      <transition-expanded>
        <component :is="`step-${internalStep + 1}`" @nextStep="nextStep" />
      </transition-expanded>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "step-1": () => import("@/components/Boxes/Steps/Step1"),
    "step-2": () => import("@/components/Boxes/Steps/Step2"),
    "step-3": () => import("@/components/Boxes/Steps/Step3"),
    "step-4": () => import("@/components/Boxes/Steps/Step4"),
    "step-5": () => import("@/components/Boxes/Steps/Step5"),
    "transition-expanded": () => import("@/components/TransitionExpanded")
  },
  data() {
    return {
      pixelConstant: 150,
      touchstartY: null,
      touchstartX: null,
      touchendY: null,
      touchendX: null,
      internalStep: 0
    }
  },
  mounted() {
    console.log("mounted", this.$refs)
    const touchableElement = this.$refs.touch
    touchableElement.addEventListener(
      "touchstart",
      function (event) {
        this.touchstartX = event.changedTouches[0].screenX
        this.touchstartY = event.changedTouches[0].screenY
      }.bind(this),
      false
    )

    touchableElement.addEventListener(
      "touchend",
      function (event) {
        this.touchendX = event.changedTouches[0].screenX
        this.touchendY = event.changedTouches[0].screenY
        this.handleGesture()
      }.bind(this),
      false
    )
  },
  methods: {
    nextStep() {
      if (this.internalStep < 4) this.internalStep += 1
    },
    prevStep() {
      console.log("PREV STEP", this.internalStep)
      if (this.internalStep > 0) this.internalStep -= 1
    },
    handleGesture() {
      if (
        this.touchendX - this.touchstartX < this.pixelConstant &&
        this.internalStep < 3
      ) {
        this.nextStep()
        console.log("Swiped Left")
      }

      if (
        this.touchendX - this.touchstartX > -this.pixelConstant &&
        this.internalStep < 3
      ) {
        console.log("Swiped Right")
        this.prevStep()
      }

      if (this.touchendY < this.touchstartY) {
        console.log("Swiped Up")
      }

      if (this.touchendY > this.touchstartY) {
        console.log("Swiped Down")
      }

      if (this.touchendY === this.touchstartY) {
        console.log("Tap")
      }
    }
  }
}
</script>

<style></style>
