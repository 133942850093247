<script>
import { Doughnut } from "vue-chartjs"
export default {
  extends: Doughnut,
  props: ["ranges", "settings"],
  data() {
    //"https://jsfiddle.net/rjtsbeLc/3/"
    return {
      gradient: null,
      CANVAS: null
    }
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: ["black", "red", "black"],
          datasets: [
            {
              label: "# of Votes",
              data: this.ranges,
              backgroundColor: [
                this.settings.rangeColor,
                "rgba(0,0,0,0)",
                this.settings.rangeColor
              ],
              borderColor: "rgb(55, 118, 133,0.01)",
              /* borderColor: [
                "rgba(255, 255, 255 ,1)",
                "rgba(255, 255, 255 ,1)",
                "rgba(255, 255, 255 ,1)"
              ], */
              borderWidth: 4,
              fill: true
            }
          ]
        },
        {
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          },
          responsive: false,
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          cutoutPercentage: 65
          /* scale: {
            angleLines: { display: true },
            ticks: {
              beginAtZero: true,
              suggestedMin: 50,
              suggestedMax: 100,
              stepSize: 1
            }
          } */
        }
      )
    }
  },
  mounted() {
    // Overwriting base render method with actual data.

    this.render()
  },
  computed: {
    data() {
      return this.ranges
    }
  },
  watch: {
    data() {
      this.render()
    }
  }
}
</script>
